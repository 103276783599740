import React from "react";
import welcomeImage from "../../../images/DSCF4691-HDR (Groot).jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div id="welcome" className="block-container">
      <div className="block-information">
        <div className="block-title">{t("welcome")}</div>
        <div className="block-text">{t("welcomeText1")}</div>
        <div className="block-text">{t("welcomeText2")}</div>
        <div className="button-group top-50">
          <a
            href="https://www.booking.com/hotel/be/gerania-gv02.html?aid=2375516&label=01J2QCS6FYYQE331Y2B7KFK398_01J9E1A45WWTA1JEDCTY72F73J"
            className="button-primary"
            target="_blank"
            rel="noreferrer"
          >
            {t("bookNow")}
          </a>
          <Link to="/around" className="button-third">
            {t("around")}
          </Link>
        </div>
      </div>
      <div className="block-img-container">
        <img src={welcomeImage} alt="gerania-gv02" />
      </div>
    </div>
  );
};

export default Welcome;
