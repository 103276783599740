import React from "react";
import LandingPageImage from "../../../images/DSCF4733 (Groot).jpeg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="landing-page-container">
        <div className="landing-page-information-container">
          <div className="landing-page-title" data-aos="fade-up">
            {t("landingText")}
          </div>
          <div className="address" data-aos="fade-up">
            {t("address")}
          </div>
        </div>
        <div className="landing-page-buttons" data-aos="fade-up">
          <a
            href="https://www.booking.com/hotel/be/gerania-gv02.html?aid=2375516&label=01J2QCS6FYYQE331Y2B7KFK398_01J9E1A45WWTA1JEDCTY72F73J"
            target="_blank"
            rel="noreferrer"
            className="button-primary"
          >
            {t("bookNow")}
          </a>
          <a href="#welcome" className="button-secondary">
            {t("discover")}
          </a>
        </div>
        <div className="landing-page-img-container">
          <img src={LandingPageImage} alt="gerania-logo" />
        </div>
      </div>
    </>
  );
};

export default Landing;
