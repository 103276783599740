import React from "react";
import aboutImage from "../../../images/DSCF4714 (Groot).jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BlockItem = ({ title, description }) => {
  return (
    <div className="block-item">
      <span className="star">*</span>
      <div>
        <b>{title}</b> {description}
      </div>
    </div>
  );
};

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="block-container">
      <div className="block-information">
        <div className="block-title"> {t("about")}</div>
        <div className="block-text">{t("aboutText1")}</div>
        <div className="block-text">
          <BlockItem
            title={t("aboutItemTitle1")}
            description={t("aboutItemText1")}
          />
          <BlockItem
            title={t("aboutItemTitle2")}
            description={t("aboutItemText2")}
          />
          <BlockItem
            title={t("aboutItemTitle3")}
            description={t("aboutItemText3")}
          />
          <BlockItem
            title={t("aboutItemTitle4")}
            description={t("aboutItemText4")}
          />
        </div>

        <div className="button-group top-50">
          <a
            href="https://www.booking.com/hotel/be/gerania-gv02.html?aid=2375516&label=01J2QCS6FYYQE331Y2B7KFK398_01J9E1A45WWTA1JEDCTY72F73J"
            target="_blank"
            rel="noreferrer"
            className="button-primary "
          >
            {t("bookNow")}
          </a>
          <Link to="/contact" className="button-third ">
            {t("contact")}
          </Link>
        </div>
      </div>
      <div className="block-img-container">
        <img src={aboutImage} alt="gerania-gv02" />
      </div>
    </div>
  );
};

export default About;
