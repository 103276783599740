import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdOutlineCall } from "react-icons/md";

const Contact = () => {
  window.scrollTo(0, 0);

  const [status, setStatus] = useState("");
  const [icon, setIcon] = useState("");
  const form = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_r1qmz1g", // Replace with your EmailJS service ID
        "template_3nrdgfj", // Replace with your EmailJS template ID
        form.current, // This references the form element
        "BGkOpbq_V624LS8c9" // Replace with your EmailJS public key
      )
      .then(
        (result) => {
          setStatus(t("sendSuccess"));
          setIcon("success");
        },
        (error) => {
          setStatus(t("sendError"));
          setIcon("error");
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className="page-container">
        <div className="block-information">
          <div className="block-title">{t("contactTitle")}</div>
          <div className="block-text">{t("contactText")}</div>
          <div className="block-text">
            <div className="together">
              <MdOutlineEmail />
              <div>casper@isytech.be</div>
            </div>
            <div className="together">
              <MdOutlineCall />
              <div>+32 468 17 12 64</div>
            </div>
            <div className="together">
              <MdOutlineLocationOn />
              <div>{t("address")}</div>
            </div>
          </div>
        </div>
        <div className="around-title">{t("information")}</div>

        <div
          className={
            status === `${t("sendSuccess")}`
              ? "green"
              : status === `${t("sendError")}`
              ? "red"
              : ""
          }
        >
          {icon === "success" ? (
            <IoMdCheckmarkCircleOutline />
          ) : icon === "error" ? (
            <MdErrorOutline />
          ) : (
            ""
          )}

          {status}
        </div>
        <form
          data-aos="fade-up"
          data-aos-duration="1000"
          ref={form}
          onSubmit={sendEmail}
          className="mailForm"
        >
          <div className="rijForm">
            <div className="colomForm">
              <div className="inputlabel">{t("name")}</div>
              <input
                placeholder="Jan Janssens"
                name="name"
                type="text"
                className="inputField"
                required
              />
            </div>
            <div className="colomForm">
              <div className="inputlabel">{t("email")}</div>
              <input
                placeholder="janjanssens@mail.com"
                name="email"
                type="email"
                className="inputField"
                required
              />
            </div>
          </div>
          <div className="rijForm">
            <div className="colomForm">
              <div className="inputlabel">{t("subject")}</div>
              <select name="category" className="inputFieldcat">
                <option value="algemeen">{t("option1")}</option>
                <option value="vraag">{t("option2")}</option>
                <option value="inschijving">{t("option3")}</option>
                <option value="anders">{t("option4")}</option>
              </select>
            </div>
            <div className="colomForm">
              <div className="inputlabel">{t("title")}</div>
              <input
                placeholder={t("placeholderTitle")}
                name="subject"
                type="text"
                className="inputField"
                required
              />
            </div>
          </div>

          <div className="rijFormFull">
            <div className="inputlabel">{t("message")}</div>
            <textarea
              placeholder={t("placeholderMessage")}
              name="text"
              className="inputFieldBig"
              required
            />
            <button type="submit" className="btnadd">
              {t("send")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact;
