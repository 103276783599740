import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div>
        <div className="footer-part-title crete">GERANIA</div>
        <div className="footer-socials">
          <a
            href="https://www.facebook.com/profile.php?id=61556879200035"
            target="_blank"
          >
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/" target="_blank">
            <FaInstagram />
          </a>
          <a href="https://www.whatsapp.com/" target="_blank">
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
